const apiStore = {
  namespaced: true,
  actions: {
    startLoading({ commit }, { url, method }) {
      commit("START_LOADING", { url, method });
      return Promise.resolve();
    },
    endLoading({ commit, state }, { url, method }) {
      if (state.loading[JSON.stringify({ url, method })]) {
        commit("END_LOADING", { url, method });
      }

      return Promise.resolve();
    },
    endLoadingUnidentifiableEndpoint({ commit, getters }) {
      if (getters.activeAPICallCount <= 1) {
        // if there is only one active endpoint loading, this error must be related to that original api call
        commit("RESET");
      }
      return Promise.resolve();
    },
    reset({ commit }) {
      commit("RESET");
    },
  },
  mutations: {
    RESET(state) {
      Object.getOwnPropertyNames(state.loading).forEach((prop) => {
        delete state.loading[prop];
      });
    },
    START_LOADING(state, urlAndMethod) {
      const newUrlAndMethod = {
        url: urlAndMethod.url,
        method: urlAndMethod.method,
        startTime: new Date(),
      };
      state.loading = {
        ...state.loading,
        [JSON.stringify(urlAndMethod)]: newUrlAndMethod,
      };
    },
    END_LOADING(state, urlAndMethod) {
      delete state.loading[JSON.stringify(urlAndMethod)];
    },
  },
  state: () => ({
    loading: {},
  }),
  getters: {
    activeAPICallCount(state) {
      return Object.keys(state.loading).length;
    },
    activeAPICallUrls(state) {
      return Object.values(state.loading).map((entry) => entry.url);
    },
    isLoading(state) {
      return Object.keys(state.loading).length > 0;
    },
  },
};

export default apiStore;
