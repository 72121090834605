class PerplexicaClient {
  #vueApp = null;

  #webSocket = null;

  #messages = {};

  #URL = null;

  #jwtToken = null;

  #sendAttempts = 0;

  #maxSendAttempts = 10;

  #sending = false;

  ready = false;

  focusModes = [
    "webSearch",
    "academicSearch",
    "writingAssistant",
    "wolframAlphaSearch",
    "youtubeSearch",
    "redditSearch",
  ];

  #focusMode = null;

  chatHistory = [];

  onmessagefinished = null;

  onrecieved = null;

  onerror = null;

  constructor(app, maxSendAttempts = null) {
    this.#vueApp = app;

    [this.#focusMode] = this.focusModes;

    if (maxSendAttempts) {
      this.#maxSendAttempts = maxSendAttempts;
    }

    const config = {
      chatModel: "gemini-1.0-pro (Latest)",
      chatModelProvider: "vertexai",
      embeddingModel: "Text Gecko default",
      embeddingModelProvider: "vertexai",
    };

    const params = encodeURI(
      `?chatModel=${config.chatModel}&chatModelProvider=${config.chatModelProvider}&embeddingModel=${config.embeddingModel}&embeddingModelProvider=${config.embeddingModelProvider}`,
    );

    this.#URL = `${import.meta.env.VITE_PERPLEXICA_WS_PROTOCOL}://${
      import.meta.env.VITE_PERPLEXICA_URL
    }/${params}`;
  }

  // Callback triggers
  #error(errorMessage) {
    if (this.onerror) {
      this.onerror(errorMessage);
    }
  }

  #messageFinished(messageId, messageData) {
    this.#setSending(false);
    if (this.onmessagefinished) {
      this.onmessagefinished(messageId, messageData);
    }
  }

  #messageSegment(messageId, messageData, segmentText) {
    if (this.onrecieved) {
      this.onrecieved(messageId, messageData, segmentText);
    }
  }

  // Internal
  #connect(callback) {
    const protocols = ["Authorization", `${this.#jwtToken}`];

    this.#webSocket = new WebSocket(this.#URL, protocols);

    this.#webSocket.onopen = () => {
      this.ready = true;
      callback();
    };

    this.#webSocket.onclose = () => {
      this.ready = false;
      this.#webSocket = null;
    };
  }

  #getCurrentMessageData(messageId) {
    if (!(messageId in this.#messages)) {
      this.#messages[messageId] = {
        recievedMessage: "",
        sources: null,
        done: false,
      };
    }

    return this.#messages[messageId];
  }

  #getMessageHandler(message) {
    const self = this;

    const handler = (e) => {
      const data = JSON.parse(e.data);
      const { messageId } = data;
      const messageCurrentData = self.#getCurrentMessageData(messageId);

      if (data.type === "error") {
        self.#error(data.data);
        return;
      }

      if (data.type === "sources") {
        messageCurrentData.sources = data.data;
      }

      if (data.type === "message") {
        messageCurrentData.recievedMessage += data.data;
        this.#messageSegment(messageId, messageCurrentData, data.data);
      }

      if (data.type === "messageEnd") {
        self.chatHistory = [
          ...self.chatHistory,
          ["human", message],
          ["assistant", messageCurrentData.recievedMessage],
        ];

        messageCurrentData.done = true;
        self.#webSocket.onmessage = null;
        self.#messageFinished(messageId, messageCurrentData);
      }
    };

    return handler;
  }

  #getAxios() {
    return this.#vueApp.config.globalProperties.$api.axios;
  }

  #refreshToken(callback) {
    this.#getAxios()
      .get(`/get-perplexica-token/`)
      .then((resp) => {
        this.#jwtToken = resp.data.jwt;
        this.#connect(callback);
      });
  }

  #waitForConnection(callback, interval) {
    const self = this;

    if (self.#webSocket.readyState === 1) {
      callback();
    } else if (self.#sendAttempts <= self.#maxSendAttempts) {
      setTimeout(() => {
        self.#sendAttempts++;
        self.#waitForConnection(callback, interval);
      }, interval);
    } else {
      self.#error("Max attepts reached");
    }
  }

  #send(message) {
    this.#waitForConnection(() => {
      this.#sendNow(message);
    }, 1000);
  }

  #sendNow(message) {
    if (!this.#webSocket || !this.ready) {
      this.#error("No open socket");
      return;
    }

    this.#webSocket.onmessage = this.#getMessageHandler(message);

    const data = JSON.stringify({
      type: "message",
      content: message,
      focusMode: this.#focusMode,
      history: [...this.chatHistory, ["human", message]],
    });

    this.#webSocket.send(data);
  }

  #canSend() {
    return !this.#sending;
  }

  #setSending(isSending) {
    this.#sending = isSending;
  }

  // Public
  setFocusMode(focusMode) {
    if (!this.focusModes.includes(focusMode)) {
      this.#error(`Unknown mode $(focusMode)`);
      return;
    }

    this.#focusMode = focusMode;
  }

  send(message) {
    if (!this.#canSend()) {
      this.#error("Still processing current request");
      return;
    }

    this.#setSending(true);
    this.#sendAttempts = 0;

    if (!this.#webSocket || !this.ready) {
      this.#refreshToken(() => {
        this.#send(message);
      });
      return;
    }

    this.#send(message);
  }

  endSession() {
    this.#webSocket?.close();
    this.chatHistory = [];
    this.#messages = {};
  }
}

export default {
  install(app) {
    app.config.globalProperties.$perplexica = new PerplexicaClient(app);
  },
};
