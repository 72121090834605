import { createStore } from "vuex";
import axios from "axios";
import createPersistedState from "vuex-persistedstate";
import * as Sentry from "@sentry/vue";
import apiStore from "./api-store";
import loaderStore from "./loader-store";
import { keysToSnake, keysToCamel } from "../utils";

// Helpers
const helpers = {
  getCurrentYear() {
    return new Date().getFullYear();
  },
  apiBaseUrl() {
    return import.meta.env.VITE_API_BASE_URL;
  },
  login(data) {
    const payload = keysToSnake(data);
    return axios.post(`${helpers.apiBaseUrl()}/login/`, payload, {
      withCredentials: true,
    });
  },
  loginViaToken(hashedId, token) {
    const payload = keysToSnake({ passwordResetCode: token });
    return axios.post(
      `${helpers.apiBaseUrl()}/person/${hashedId}/link/`,
      payload,
      { withCredentials: true },
    );
  },
  register(data) {
    const payload = keysToSnake(data);
    return axios.post(`${helpers.apiBaseUrl()}/register/`, payload, {
      withCredentials: true,
    });
  },
  sendForgotPassword(data) {
    const payload = keysToSnake(data);
    return axios.put(
      `${helpers.apiBaseUrl()}/person/send-password-reset-email/`,
      payload,
      {
        withCredentials: true,
      },
    );
  },
  resetPassword(hashedId, data) {
    const payload = keysToSnake(data);
    return axios.post(
      `${helpers.apiBaseUrl()}/person/${hashedId}/reset-password/`,
      payload,
      {
        withCredentials: true,
      },
    );
  },
  sendVerifyEmail(hashedId, data) {
    const payload = keysToSnake(data);
    let url = "";
    if (hashedId) {
      url = `${helpers.apiBaseUrl()}/person/mine/send-verification/${hashedId}/`;
    } else {
      url = `${helpers.apiBaseUrl()}/person/mine/send-verification/`;
    }
    return axios.put(url, payload, {
      withCredentials: true,
    });
  },
  getPersonAgreements() {
    return axios.get(`${helpers.apiBaseUrl()}/person/mine/agreement/`, {
      withCredentials: true,
    });
  },
  verifyEmail(emailAddressHashedId, data) {
    const payload = keysToSnake(data);
    return axios.put(
      `${helpers.apiBaseUrl()}/person/verify-email/${emailAddressHashedId}/`,
      payload,
      {
        withCredentials: true,
      },
    );
  },
  verifyCollaborationInquiryEmail(collaborationInquiryHashedId, data) {
    const payload = keysToSnake(data);
    return axios.put(
      `${helpers.apiBaseUrl()}/collaboration-inquiry/${collaborationInquiryHashedId}/verify-email/`,
      payload,
      {
        withCredentials: true,
      },
    );
  },
  acceptInvitation(hashedId, data) {
    const payload = keysToSnake(data);
    return axios.post(`${helpers.apiBaseUrl()}/accept-invitation/`, payload, {
      withCredentials: true,
    });
  },
  refreshToken() {
    return axios.post(
      `${helpers.apiBaseUrl()}/refresh/`,
      {},
      { withCredentials: true },
    );
  },
  logout() {
    return axios.post(
      `${helpers.apiBaseUrl()}/logout/`,
      {},
      { withCredentials: true },
    );
  },
  setOnesignalUser(person, userIdAuthHash) {
    /* eslint-disable */
    if (!window.OneSignal) { return; }

    window.OneSignal.push(function() {
      window.OneSignal.setExternalUserId(person.hashedId, userIdAuthHash);
    });
    /* eslint-enable */
  },
};

const plugins = [];

if (!import.meta.env.SSR) {
  plugins.push(
    createPersistedState({
      storage: window.sessionStorage,
      reducer: (state) => {
        const persistableState = { ...state };
        if (persistableState?.vueApp) {
          // do not try to serialize the vue app object to sessionStorage
          delete persistableState.vueApp;
        }
        return persistableState;
      },
    }),
  );
}
export default createStore({
  debug: true,
  plugins,
  modules: {
    api: apiStore,
    loader: loaderStore,
  },
  state: {
    person: {},
    accessToken: "",
    impersonator: {},
    isCheckingAuth: false,
  },
  actions: {
    "api/endLoading": async function () {
      console.log("done loading"); // eslint-disable-line
    },
    async login(context, data) {
      await context.commit("setIsCheckingAuth", true);
      try {
        const response = await helpers.login(data);
        const person = keysToCamel(response.data.person);
        const impersonator = keysToCamel(response.data.impersonator);
        await context.commit("setAccessToken", response.data.access_token);
        await context.commit("setPerson", { person, impersonator });
        const agreementResponse = await helpers.getPersonAgreements();
        await context.commit(
          "setPersonAgreements",
          keysToCamel(agreementResponse.data.results),
        );
        await context.commit(
          "setUserIdAuthHash",
          response.data.onesignal_external_id_hash,
        );
        helpers.setOnesignalUser(
          keysToCamel(response.data.person),
          response.data.onesignal_external_id_hash,
        );
        return response.data;
      } catch (error) {
        return Promise.reject(error);
      } finally {
        await context.commit("setIsCheckingAuth", false);
      }
    },
    async loginViaToken(context, data) {
      await context.commit("setIsCheckingAuth", true);
      try {
        const response = await helpers.loginViaToken(data.hashedId, data.token);
        const person = keysToCamel(response.data.person);
        const impersonator = keysToCamel(response.data.impersonator);
        await context.commit("setAccessToken", response.data.access_token);
        await context.commit("setPerson", { person, impersonator });

        const agreementResponse = await helpers.getPersonAgreements();
        await context.commit(
          "setPersonAgreements",
          keysToCamel(agreementResponse.data.results),
        );
        await context.commit(
          "setUserIdAuthHash",
          response.data.onesignal_external_id_hash,
        );
        helpers.setOnesignalUser(
          keysToCamel(response.data.person),
          response.data.onesignal_external_id_hash,
        );
        if (this.$gtag) {
          this.$gtag.event("login_success", {
            event_category: "authentication",
            event_label: "token",
          });
        }
        return response.data;
      } catch (error) {
        return Promise.reject(error);
      } finally {
        await context.commit("setIsCheckingAuth", false);
      }
    },
    async register(context, data) {
      await context.commit("setIsCheckingAuth", true);
      try {
        const response = await helpers.register(data);
        const person = keysToCamel(response.data.person);
        const impersonator = keysToCamel(response.data.impersonator);
        await context.commit("setAccessToken", response.data.access_token);
        await context.commit("setPerson", { person, impersonator });

        const agreementResponse = await helpers.getPersonAgreements();
        await context.commit(
          "setPersonAgreements",
          keysToCamel(agreementResponse.data.results),
        );
        await context.commit(
          "setUserIdAuthHash",
          response.data.onesignal_external_id_hash,
        );
        helpers.setOnesignalUser(
          keysToCamel(response.data.person),
          response.data.onesignal_external_id_hash,
        );
        return response.data;
      } catch (error) {
        return Promise.reject(error);
      } finally {
        await context.commit("setIsCheckingAuth", false);
      }
    },
    async sendForgotPassword(context, data) {
      try {
        const response = await helpers.sendForgotPassword(data);
        return response.data;
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async resetPassword(context, data) {
      try {
        const response = await helpers.resetPassword(data.hashedId, data);
        return response.data;
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async sendVerifyEmail(context, data) {
      try {
        const response = await helpers.sendVerifyEmail(data.hashedId, data);
        return response.data;
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async verifyEmail(context, data) {
      try {
        const response = await helpers.verifyEmail(data.hashedId, data);
        return response.data;
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async verifyCollaborationInquiryEmail(context, data) {
      try {
        const response = await helpers.verifyCollaborationInquiryEmail(
          data.hashedId,
          data,
        );
        return response.data;
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async acceptInvitation(context, data) {
      try {
        const response = await helpers.acceptInvitation(data.hashedId, data);
        return response.data;
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async refreshToken(context) {
      await context.commit("setIsCheckingAuth", true);
      try {
        const response = await helpers.refreshToken();
        const person = keysToCamel(response.data.person);
        const impersonator = keysToCamel(response.data.impersonator);
        const agreementResponse = await helpers.getPersonAgreements();
        await context.commit("setAccessToken", response.data.access_token);
        await context.commit("setPerson", { person, impersonator });
        await context.commit(
          "setPersonAgreements",
          keysToCamel(agreementResponse.data.results),
        );

        await context.commit(
          "setUserIdAuthHash",
          response.data.onesignal_external_id_hash,
        );
        helpers.setOnesignalUser(
          keysToCamel(response.data.person),
          response.data.onesignal_external_id_hash,
        );
        return response.data;
      } catch (error) {
        await helpers.logout();
        await context.commit("setAccessToken", null);
        await context.commit("setPerson", { person: null, impersonator: null });
        helpers.setOnesignalUser(
          { hashedId: null },
          context.getters.userIdAuthHash,
        );
        await context.commit("setUserIdAuthHash", null);
        return Promise.reject(error);
      } finally {
        await context.commit("setIsCheckingAuth", false);
      }
    },
    async logout(context) {
      await context.commit("setIsCheckingAuth", true);
      try {
        const response = await helpers.logout();
        await context.commit("setAccessToken", null);
        await context.commit("setPerson", { person: null, impersonator: null });
        helpers.setOnesignalUser(
          { hashedId: null },
          context.getters.userIdAuthHash,
        );
        await context.commit("setUserIdAuthHash", null);
        return response.data;
      } catch (error) {
        return Promise.reject(error);
      } finally {
        await context.commit("setIsCheckingAuth", false);
      }
    },
    updateConnections(context, data) {
      context.commit("setPersonConnections", data);
      return data;
    },
  },
  mutations: {
    setAccessToken(state, payload) {
      state.accessToken = payload;
    },
    setPerson(state, payload) {
      const $app = this.$app.config.globalProperties;
      if (payload.person && payload.person.calc_Connections) {
        payload.person.calc_Connections = keysToCamel(
          JSON.parse(payload.person.calc_Connections),
        );
      }
      if (payload.person && payload.person.calc_Permissions) {
        payload.person.calc_Permissions = keysToCamel(
          JSON.parse(payload.person.calc_Permissions),
        );
      }
      state.person = payload.person;
      state.impersonator = payload.impersonator;

      // send impersonator information
      if (state.impersonator && Object.keys(state.impersonator).length > 0) {
        if (import.meta.env.VITE_SENTRY_DSN !== "null") {
          Sentry.setUser({
            id: state.impersonator.hashedId,
            email: state.impersonator.calc_EmailAddress,
            impersonatingPersonId: state.person?.hashedId,
            isTestAccount: state.impersonator?.isTestAccount,
            isImpersonatingTestAccount: state.person?.isTestAccount,
          });
        }
        if ($app.$gtag) {
          $app.$gtag.config({ user_id: state.impersonator.hashedId });
          if (state.impersonator.personType) {
            $app.$gtag.set({
              user_properties: {
                person_type: state.impersonator.personType.value,
              },
            });
          }
        }
        if ($app.$posthog) {
          if (state.impersonator.personType) {
            $app.$posthog.identify(state.impersonator.hashedId, {
              email: state.impersonator.calc_EmailAddress,
              personType: state.impersonator.personType.value,
              impersonatingPersonId: state.person?.hashedId,
              isTestAccount: state.impersonator?.isTestAccount,
              isImpersonatingTestAccount: state.person?.isTestAccount,
            });
          }
        }
        // send real user information
      } else if (state.person && Object.keys(state.person).length > 0) {
        if (import.meta.env.VITE_SENTRY_DSN !== "null") {
          Sentry.setUser({
            id: state.person.hashedId,
            email: state.person.calc_EmailAddress,
            isTestAccount: state.person.isTestAccount,
          });
        }
        if ($app.$gtag) {
          $app.$gtag.config({ user_id: state.person.hashedId });
          if (state.person.personType) {
            $app.$gtag.set({
              user_properties: {
                person_type: state.person.personType.value,
              },
            });
          }
        }
        if ($app.$posthog) {
          if (state.person.personType) {
            $app.$posthog.identify(state.person.hashedId, {
              email: state.person.calc_EmailAddress,
              personType: state.person.personType.value,
              isTestAccount: state.person.isTestAccount,
            });
          }
        }
      } else {
        if (import.meta.env.VITE_SENTRY_DSN !== "null") {
          Sentry.configureScope((scope) => scope.setUser(null));
        }
        if ($app.$posthog) {
          $app.$posthog.reset();
        }
      }
    },
    setPersonConnections(state, payload) {
      state.person.calc_Connections = payload;
    },
    setPersonAgreements(state, payload) {
      state.person.agreements = payload;
    },
    setUserIdAuthHash(state, payload) {
      state.userIdAuthHash = payload;
    },
    setIsCheckingAuth(state, payload) {
      state.isCheckingAuth = payload;
    },
  },
  getters: {
    accessToken: (state) => state.accessToken,
    isCheckingAuth: (state) => state.isCheckingAuth,
    person: (state) => state.person,
    impersonator: (state) => state.impersonator,
    isImpersonating: (state) => state.impersonator?.calc_EmailAddress,
    isPersonLoggedIn: (state) => state.person?.calc_EmailAddress,
    userIdAuthHash: (state) => state.userIdAuthHash,
    hasPersonAgreed: (state) => state.person.agreements?.length >= 2,
  },
});
