import axios from "axios";
import apiPlugin, { resetCancelToken } from "./api";
import perplexicaPlugin from "./perplexica";
import posthogPlugin from "./posthog";
import { latexPlugin } from "./toast-ui-latex-plugin.js";

export {
  apiPlugin,
  posthogPlugin,
  resetCancelToken,
  latexPlugin,
  perplexicaPlugin,
};

export function isValidAccessToken(jwt, expireEarlySeconds = 0) {
  if (!jwt || jwt.split(".").length < 3) {
    return false;
  }
  const data = JSON.parse(atob(jwt.split(".")[1]));
  let expireAt = data.exp;
  if (expireEarlySeconds) {
    // Force token to expire a little early to account for network time, as necessary
    expireAt -= expireEarlySeconds;
  }
  const exp = new Date(expireAt * 1000); // JS deals with dates in milliseconds since epoch
  const now = new Date();
  return now < exp;
}

export function snakeToCamel(s) {
  return s.replace(/([-_][a-z])/gi, ($1) =>
    $1.toUpperCase().replace("-", "").replace("_", ""),
  );
}

export function camelToSnake(s) {
  if (s === s.toUpperCase()) {
    return s;
  }
  return s.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}

export function isArray(a) {
  return Array.isArray(a);
}

export function isObject(o) {
  return o === Object(o) && !isArray(o) && typeof o !== "function";
}

export function keysToCamel(o) {
  if (isObject(o)) {
    const n = {};

    Object.keys(o).forEach((k) => {
      n[snakeToCamel(k)] = keysToCamel(o[k]);
    });

    return n;
  }
  if (isArray(o)) {
    return o.map((i) => keysToCamel(i));
  }

  return o;
}

export function scrollIntoViewWithOffset(el, offset, container) {
  container.scrollTo({
    behavior: "smooth",
    top:
      el.getBoundingClientRect().top -
      document.body.getBoundingClientRect().top -
      offset,
  });
}

export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export function isStrongPassword(password) {
  // eslint-disable-next-line
  const strongPassword = new RegExp(
    "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})",
  );
  return strongPassword.test(password);
}

export function submitHubspotForm(guid, hutk, fields, pageName) {
  const preparedFields = [];
  Object.keys(fields).forEach((fieldKey) => {
    preparedFields.push({
      objectTypeId: "0-1",
      name: fieldKey,
      value: fields[fieldKey],
    });
  });

  const body = {
    fields: preparedFields,
    context: {
      hutk,
      pageUri: window.location.href,
      pageName,
    },
    legalConsentOptions: {
      consent: {
        // Include this object when GDPR options are enabled
        consentToProcess: true,
        text: "I agree to allow NotedSource to store and process my personal data.",
        communications: [
          {
            value: true,
            subscriptionTypeId: 999,
            text: "I agree to receive marketing communications from NotedSource.",
          },
        ],
      },
    },
  };
  const url = `https://api.hsforms.com/submissions/v3/integration/submit/9144564/${guid}`;
  axios
    .post(url, body, { headers: { "Content-Type": "application/json" } })
    .then((resp) => {
        console.log(resp); // eslint-disable-line
    });
}

export function avatarUrl(profile, selectedPerson) {
  let person = selectedPerson;
  if (!selectedPerson) {
    person = profile.person;
  }
  if (profile?.imageHash) {
    return `${import.meta.env.VITE_API_BASE_URL}/public-file/${
      profile.imageHash
    }/content/`;
  }
  if (person && person.firstName && person.lastName) {
    return `${
      import.meta.env.VITE_API_BASE_URL
    }/avatar/${person.firstName.replace(
      /[^0-9a-z]/gi,
      "",
    )}%20${person.lastName.replace(/[^0-9a-z]/gi, "")}/`;
  }
  return `${import.meta.env.VITE_API_BASE_URL}/avatar/noted%20source/`;
}

export function avatarUrlFromString(string) {
  return `${
    import.meta.env.VITE_API_BASE_URL
  }/avatar/${string.replace(
    /[^0-9a-z ]/gi,
    "",
  ).replaceAll(' ', '%20')}/`;
}

export function imageUrl(imageHash) {
  return `${import.meta.env.VITE_API_BASE_URL}/file/${imageHash}/content/`;
}

export function logoUrl(collaborationSpace) {
  if (collaborationSpace?.logoHash) {
    return `${import.meta.env.VITE_API_BASE_URL}/public-file/${
      collaborationSpace.logoHash
    }/content/`;
  }
  if (collaborationSpace && collaborationSpace.name) {
    const words = collaborationSpace.name.split(" ");
    if (words.length > 1) {
      return `${import.meta.env.VITE_API_BASE_URL}/avatar/${words[0][0]}%20${
        words[1][0]
      }/`;
    }
    return `${import.meta.env.VITE_API_BASE_URL}/avatar/${words[0][0]}/`;
  }
  return `${import.meta.env.VITE_API_BASE_URL}/avatar/noted%20source/`;
}

export async function checkAccessToken(vm) {
  const { accessToken } = vm.$store.state;
  if (!isValidAccessToken(accessToken)) {
    return vm.$api.attemptAccessTokenRefresh().then(() => {
      const { newAccessToken } = vm.$store.state;
      if (!isValidAccessToken(newAccessToken)) {
        vm.$router.push("/auth/login");
        return Promise.reject(new Error("Requires login."));
      }
      return Promise.resolve();
    });
  }
  return Promise.resolve();
}

export async function currentCollaborationSpace(vm) {
  return checkAccessToken(vm).then(() => {
    const collaborationSpaceRole =
      vm.$store.getters.person.collaborationSpaceRoles.find((role) => {
        return (
          role.collaborationSpace.hashedId ===
          vm.$route.params.collaborationSpaceId
        );
      });
    return collaborationSpaceRole.collaborationSpace;
  });
}

export function currentProfile(vm) {
  return vm.$store.getters.person.profile.hashedId;
}

export function bannerUrl(vm, banner, width, typeOfBanner) {
  let b = banner;
  if (!b) {
    if (typeOfBanner === "researchCommunity") {
      return null;
    }
    if (typeOfBanner === "profile") {
      const profile = currentProfile(vm);
      b = profile.banner;
    } else {
      const collaborationSpace = currentCollaborationSpace(vm);
      b = collaborationSpace.banner;
    }
  }
  if (b?.unsplashUrlRaw) {
    return `${b.unsplashUrlRaw}&fit=crop&crop=entropy&w=${
      width || "1000"
    }&h=400&dpr=2`;
  }
  if (b?.fileHash) {
    return `${import.meta.env.VITE_API_BASE_URL}/file/${b.fileHash}/content/`;
  }
  return null;
}

export function bannerAttribution(vm, banner, typeOfBanner) {
  let b = banner;
  if (!b) {
    if (typeOfBanner === "researchCommunity") {
      return null;
    }
    if (typeOfBanner === "profile") {
      const profile = currentProfile(vm);
      b = profile.banner;
    } else {
      const collaborationSpace = currentCollaborationSpace(vm);
      b = collaborationSpace.banner;
    }
  }
  if (b?.unsplashUserUsername) {
    return `Photo by <a target="_blank" href="https://unsplash.com/@${b?.unsplashUserUsername}?utm_source=NotedSource&amp;utm_medium=referral">${b?.unsplashUserName}</a> on <a target="_blank" href="https://unsplash.com?utm_source=NotedSource&amp;utm_medium=referral">Unsplash</a>`;
  }
  return null;
}

export function imageExists(url, onSuccess, onError) {
  const img = new Image();
  img.onload = onSuccess;
  img.onerror = onError;
  img.src = url;
}

export function loadEnums(vm, enums, callback) {
  const keys = Object.keys(enums);
  const enumPath = `${import.meta.env.VITE_API_BASE_URL}/enum/${keys.join(
    "|",
  )}/`;
  const axiosInstance = vm?.$api?.axiosPublic || axios;
  return axiosInstance
    .get(`${enumPath}`)
    .then((res) => {
      const { results } = keysToCamel(res.data);
      if (keys.length === 1) {
        vm.enums[enums[keys[0]]] = results;
      } else {
        keys.forEach((key) => {
          vm.enums[enums[key]] = results[key];
        });
      }
      if (callback) {
        callback();
      }
      return vm.enums;
    })
    .catch((error) => {
      throw error;
    });
}

export function imageUrlFromProfile(profile) {
  if (profile.imageHash) {
    return `${import.meta.env.VITE_API_BASE_URL}/public-file/${
      profile.imageHash
    }/content/`;
  }
  if (profile.person && profile.person.firstName && profile.person.lastName) {
    return `${import.meta.env.VITE_API_BASE_URL}/avatar/${
      profile.person.firstName
    }%20${profile.person.lastName}/`;
  }
  return `${import.meta.env.VITE_API_BASE_URL}/avatar/noted%20source/`;
}

export function keysToSnake(o, coerceToObject = false) {
  if (isObject(o)) {
    const n = {};

    Object.keys(o).forEach((k) => {
      if (coerceToObject && k.indexOf(".") > -1) {
        const ks = k.split(".");
        if (ks.length > 2) {
          // eslint-disable-next-line no-console
          console.error("coercion to object only supports one nested level");
        }
        if (Object.keys(n).indexOf(ks[0]) === -1) {
          n[camelToSnake(ks[0])] = {};
        }
        n[camelToSnake(ks[0])][camelToSnake(ks[1])] = keysToSnake(o[k]);
      } else {
        n[camelToSnake(k)] = keysToSnake(o[k]);
      }
    });

    return n;
  }
  if (isArray(o)) {
    return o.map((i) => keysToSnake(i));
  }

  return o;
}

export function asDollars(amount) {
  return amount.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
}

/**
 * This will assign a global object to the entire vue application, which will be accessible in
 * every vue component throughout the life of the vue app.
 * this is intended to be used by plugin installers and the app-initializer exclusively
 * @param vueObject - the vue app object result of (createApp(...) or new Vue(...))
 * @param name - the name the global object should be accessible by: IE '$log'
 * @param val - the object to be assigned
 */
export function assignGlobalVueAppObject(vueObject, name, val) {
  // this condition supports the new global assignment strategy used in vue3
  if (vueObject.config?.globalProperties) {
    vueObject.config.globalProperties[name] = val;
  } else {
    // standard vue 2 assignment
    vueObject.prototype[name] = val;
  }
}

export function randomKey() {
  return (Math.random() + 1).toString(36).substring(7);
}

export function addRequest(vm, note) {
  const key = randomKey();
  vm.activeRequests.push({ key, text: note });
  return key;
}

export function removeRequest(vm, key, error = false) {
  const activeRequestIndex = vm.activeRequests.findIndex(
    (item) => item.key === key,
  );
  if (error) {
    vm.activeRequests[activeRequestIndex].failed = true;
  } else {
    vm.activeRequests.splice(activeRequestIndex, 1);
  }
}

export async function askQuestion(vm, description, question) {
  const key = addRequest(vm, description);
  return vm.$api.axiosThrottled
    .post(
      `/ask-question/`,
      keysToSnake({
        question,
      }),
    )
    .then((resp) => {
      removeRequest(vm, key);
      return resp;
    })
    .catch(() => {
      removeRequest(vm, key, true);
    });
}

export function pluckEnum(list, value) {
  return list.find((item) => {
    return item.value === value;
  });
}
