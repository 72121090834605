const state = {
  loading: 0,
};

const getters = {
  loading: (state_) => state_.loading,
};

const mutations = {
  START_LOADING: (state_) => state_.loading++,
  FINISH_LOADING: (state_) => state_.loading--,
};

const namespaced = true;

const loader = {
  namespaced,
  state,
  getters,
  mutations,
};

export default loader;
