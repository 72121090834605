import { HtmlGenerator, parse } from "latex.js";

export function latexPlugin() {
  const toHTMLRenderers = {
    latex(node) {
      const generator = new HtmlGenerator({ hyphenate: false });
      const { body } = parse(node.literal, { generator }).htmlDocument();

      return [
        { type: "openTag", tagName: "div", outerNewLine: true },
        { type: "html", content: body.innerHTML },
        { type: "closeTag", tagName: "div", outerNewLine: true },
      ];
    },
  };

  return { toHTMLRenderers };
}

export default {
  latexPlugin,
};
