import axios from "axios";
import rateLimit from "axios-rate-limit";
import {
  assignGlobalVueAppObject,
  isValidAccessToken,
  keysToCamel,
} from "./index";

function requestInterceptSuccess(apiClassInstance, opts, config) {
  if (config) {
    this.vueApp.config.globalProperties.$store.dispatch(
      "api/startLoading",
      config,
    );
    config.metadata = { startTime: new Date() };
    this.vueApp.config.globalProperties.$store.commit("loader/START_LOADING");
  }
  if (!opts?.withCredentials) {
    return Promise.resolve(config);
  }
  // Validate jwt
  return this.attemptAccessTokenRefresh()
    .then((token) => {
      config.headers.Authorization = `Bearer: ${token}`;
      return config;
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      this.vueApp.config.globalProperties.$store.commit(
        "loader/FINISH_LOADING",
      );
      return config;
    });
}

function requestInterceptFailure(apiClassInstance, opts, error) {
  // eslint-disable-next-line
  console.error(error);
  this.vueApp.config.globalProperties.$store.commit("loader/FINISH_LOADING");
  return Promise.reject(error);
}

function responseInterceptSuccess(apiClassInstance, opts, response) {
  if (response?.config) {
    this.vueApp.config.globalProperties.$store.dispatch(
      "api/endLoading",
      response.config,
    );
    response.config.metadata.endTime = new Date();
    response.config.metadata.duration =
      response.config.metadata.endTime - response.config.metadata.startTime;
    this.vueApp.config.globalProperties.$store.commit("loader/FINISH_LOADING");
  }
  return response;
}

function responseInterceptFailure(apiClassInstance, opts, error) {
  let cfg = null;
  if (error.config) {
    cfg = error.config;
  } else if (error.response?.config) {
    cfg = error.response.config;
  }
  if (cfg) {
    this.vueApp.config.globalProperties.$store.dispatch("api/endLoading", cfg);
  } else {
    this.vueApp.config.globalProperties.$store.dispatch(
      "api/endLoadingUnidentifiableEndpoint",
    );
  }
  this.vueApp.config.globalProperties.$store.commit("loader/FINISH_LOADING");
  const loginRequiredErrorMessage = "Login is Required.";
  if (
    error.response &&
    error.response.status === 401 &&
    error.response.data &&
    (error.response.data.result === "invalid" ||
      error.response.data.result === "expired")
  ) {
    apiClassInstance.cancelSource.cancel(loginRequiredErrorMessage); // Cancel any other pending requests
    return this.vueApp.config.globalProperties.$store
      .dispatch("logout")
      .then(() => {
        if (this.vueApp.config.globalProperties.$route.path !== "/auth/login") {
          this.vueApp.config.globalProperties.$router.replace("/auth/login");
        }
        throw loginRequiredErrorMessage;
      });
  }

  if (error.message === loginRequiredErrorMessage) {
    // Check if request was manually canceled because we need to re-authenticate
    throw loginRequiredErrorMessage; // Throw error to break out of the request's promise chain
  }

  if (
    apiClassInstance.silent404 &&
    error.response &&
    error.response.status === 404
  ) {
    return error.response;
  }
  if (error.code === "ERR_CANCELED") {
    return error.response;
  }
  if (!error.response?.data?.silent) {
    let message = "An error occurred.";
    if (error.response?.data?.message) {
      message = error.response.data.message;
    }
    if (opts?.enableGlobalToast) {
      this.vueApp.config.globalProperties.$toast.error(message);
    }
  }
  // eslint-disable-next-line
  console.error(error);
  return Promise.reject(error);
}

export class Api {
  constructor(baseUrl, silent404 = true) {
    this.baseURL = import.meta.env.VITE_API_BASE_URL;
    this.silent404 = silent404;
    this.cancelSource = axios.CancelToken.source();
  }

  _buildAxios(
    baseURL,
    opts = { enableGlobalToast: true, withCredentials: true },
  ) {
    const ax = axios.create({
      baseURL,
      withCredentials: opts.withCredentials,
      cancelToken: this.cancelSource.token,
    });
    const apiClassInstance = this;
    ax.interceptors.request.use(
      requestInterceptSuccess.bind(this, apiClassInstance, opts),
      requestInterceptFailure.bind(this, apiClassInstance, opts),
    );
    ax.interceptors.response.use(
      responseInterceptSuccess.bind(this, apiClassInstance, opts),
      responseInterceptFailure.bind(this, apiClassInstance, opts),
    );
    if (opts.maxRequests) {
      return rateLimit(ax, {
        maxRequests: opts.maxRequests,
        perMilliseconds: opts.perMilliseconds,
        maxRPS: opts.maxRPS,
      });
    }
    return ax;
  }

  attemptAccessTokenRefresh(force = false) {
    const { accessToken } = this.vueApp.config.globalProperties.$store.state;
    // If access token is within 5 seconds of expiring, refresh it
    if (!isValidAccessToken(accessToken, 5) || force) {
      // intentionally use base axios here
      return this.vueApp.config.globalProperties.$store
        .dispatch("refreshToken", {})
        .then((response) => {
          return keysToCamel(response).accessToken;
        });
    }

    return Promise.resolve(accessToken);
  }

  get axios() {
    return this._axios;
  }

  getAxiosWithOpts(opts = { enableGlobalToast: true, withCredentials: true }) {
    return this._buildAxios(this.baseURL, opts);
  }

  get axiosWithoutGlobalToast() {
    return this.getAxiosWithOpts({
      enableGlobalToast: false,
      withCredentials: true,
    });
  }

  get axiosPublic() {
    return this.getAxiosWithOpts({
      enableGlobalToast: false,
      withCredentials: false,
    });
  }

  get axiosThrottled() {
    return this.getAxiosWithOpts({
      enableGlobalToad: true,
      withCredentials: true,
      maxRequests: 1,
      perMilliseconds: 1000,
      maxRPS: 3,
    });
  }
}

export function installApiPlugin(vueApp) {
  const apiBaseUrl = import.meta.env.VITE_API_BASE_URL;
  const api = new Api(apiBaseUrl);
  api._axios = api._buildAxios(apiBaseUrl);
  api.vueApp = vueApp;
  assignGlobalVueAppObject(vueApp, "$api", api);
}

export function resetCancelToken(apiClassInstance) {
  apiClassInstance.cancelSource = axios.CancelToken.source();
  apiClassInstance._axios = apiClassInstance._buildAxios(
    import.meta.env.VITE_API_BASE_URL,
  );
}

export default {
  install(vueObject) {
    installApiPlugin(vueObject);
  },
};
